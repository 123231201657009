export const LAMBDA_ENDPOINT =
  "https://kvz60gz535.execute-api.us-east-1.amazonaws.com/prod/distance";

export const IS_PROD = process.env.NODE_ENV === "production";

export const META = {
  common: {
    image:
      "https://avatars.githubusercontent.com/u/5589732?s=400&u=9fb31deff07561ed6dc5cf3f9d3ce0385e57c0a2&v=4",
  },
  index: {
    title: "Anvil Palamattam",
    description: "Anvil Palamattam",
  },
  profile: {
    title: "Anvil Palamattam | Profile",
    description: "Anvil Palamattam",
  },
  works: {
    title: "Anvil Palamattam | Works",
    description: "Anvil Palamattam",
  },
};
